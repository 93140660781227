import axios from 'axios'
import get from 'lodash/get'

// import keycloak from '../../keycloak'
import { getAccessToken, logError, verifyExpired } from '../../utils'
// const TEST = process.env.NODE_ENV === 'test'

// const isProd = TEST ? false : window.location.hostname === 'bitcoin.me'
// const isBeta = TEST ? false : window.location.hostname === 'beta.bitcoin.me'

const MANAGER_API = process.env.REACT_APP_MANAGER_API

const MANAGER = axios.create({
	baseURL: MANAGER_API,
})

const MANAGER_AUTH = axios.create({
	baseURL: MANAGER_API,
	headers: { ClientOrigin: 3, 'Content-Type': '/application/json' },
})

MANAGER_AUTH.interceptors.request.use(async config => {
	config.headers.AuthToken = await getAccessToken()
	return config
})

// if (isProd || isBeta) {
// 	MANAGER_AUTH.interceptors.response.use(
// 		response => {
// 			return response
// 		},
// 		error => {
// 			if (error.response.status === 401) {
// 				keycloak.logout()
// 			}
// 			return error
// 		}
// 	)
// }

export const getEstimatedQuoteAmount = async ({ baseAmount, baseTokenID, quoteTokenID }) => {
	if (!baseAmount || !baseTokenID || !quoteTokenID) return

	try {
		const { data } = await MANAGER_AUTH.get(
			`v1/liquidity-pool/estimated-quote?baseAmount=${baseAmount}&baseTokenID=${baseTokenID}&quoteTokenID=${quoteTokenID}`
		)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getEstimatedQuoteAmount logError')
		const errorStatus = get(error, 'response.status', 'error in getEstimatedQuoteAmount getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/pools/getEstimatedQuoteAmount', errorResponse)
		return { error: errorResponse }
	}
}

export const getCalcAmount = async ({ baseAmount, liquidityPoolID }) => {
	if (!baseAmount || !liquidityPoolID) return

	try {
		const { data } = await MANAGER_AUTH.get(
			`v1/liquidity-pool/calc-quote?baseAmount=${baseAmount}&liquidityPoolID=${liquidityPoolID}`
		)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getCalcAmount logError')
		const errorStatus = get(error, 'response.status', 'error in getCalcAmount getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/pools/getCalcAmount', errorResponse)
		return { error: errorResponse }
	}
}

export const getLiquidityPools = async ({ id }) => {
	let query = 'v1/get-liquidity-pools'
	if (id) query = `v1/get-liquidity-pools?id=${id}`

	try {
		const { data } = await MANAGER.get(query)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getLiquidityPools logError')
		const errorStatus = get(error, 'response.status', 'error in getLiquidityPools getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/pools/getLiquidityPools', errorResponse)
		return { error: errorResponse }
	}
}

export const getUserPortions = async () => {
	try {
		const { data } = await MANAGER_AUTH.get(`v1/get-portions`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getUserPortions logError')
		const errorStatus = get(error, 'response.status', 'error in getUserPortions getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/pools/getUserPortions', errorResponse)
		return { error: errorResponse }
	}
}

export const addLiquidityPool = async ({ baseAmount, baseTokenID, liquidityPoolID, quoteAmount, quoteTokenID }) => {
	if (!baseAmount || !baseTokenID || !liquidityPoolID || !quoteAmount || !quoteTokenID) return

	try {
		const { data } = await MANAGER_AUTH.post(`v1/liquidity`, {
			baseAmount: baseAmount.toString(),
			baseTokenID,
			liquidityPoolID,
			quoteAmount: quoteAmount.toString(),
			quoteTokenID,
		})
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data', 'error in addLiquidityPool logError')
		const errorStatus = get(error, 'response.status', 'error in addLiquidityPool getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/pools/addLiquidityPool', errorResponse)
		return { error: errorResponse }
	}
}

export const claimRewards = async liquidityPoolID => {
	if (!liquidityPoolID) return

	try {
		const { data } = await MANAGER_AUTH.post(`v1/claim-liquidity-allowance`, {
			liquidityPoolID,
		})
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in claimRewards logError')
		const errorStatus = get(error, 'response.status', 'error in claimRewards getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/pools/claimRewards', errorResponse)
		return { error: errorResponse }
	}
}

export const removeLiquidity = async liquidityPoolID => {
	if (!liquidityPoolID) return

	try {
		const { data } = await MANAGER_AUTH.post(`v1/remove-liquidity`, {
			liquidityPoolID,
		})
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in removeLiquidity logError')
		const errorStatus = get(error, 'response.status', 'error in removeLiquidity getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/pools/removeLiquidity', errorResponse)
		return { error: errorResponse }
	}
}

export const getClaimsHistory = async ({ Limit = 20, Skip = 0 }) => {
	try {
		const { data } = await MANAGER_AUTH.get(`v1/get-claim-history?Limit=${Limit}&Skip=${Skip}`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getClaimsHistory logError')
		const errorStatus = get(error, 'response.status', 'error in getClaimsHistory getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/pools/getClaimsHistory', errorResponse)
		return { error: errorResponse }
	}
}

export const getPoolHistory = async ({ limit = 20, skip = 0, operation = 'ALL', status = 'LP_ALL' }) => {
	try {
		const { data } = await MANAGER_AUTH.get(
			`v1/record-history?limit=${limit}&skip=${skip}&operation=${operation}&status=${status}`
		)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getPoolHistory logError')
		const errorStatus = get(error, 'response.status', 'error in getPoolHistory getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/pools/getPoolHistory', errorResponse)
		return { error: errorResponse }
	}
}
