import axios from 'axios'
import get from 'lodash/get'
import isArray from 'lodash/isArray'

import { ORDERS_LIMIT_QUANTITY } from '../../constants'
// import keycloak from '../../keycloak'
import { getAccessToken, logError, verifyExpired } from '../../utils'
// const TEST = process.env.NODE_ENV === 'test'

// const isProd = TEST ? false : window.location.hostname === 'bitcoin.me'
// const isBeta = TEST ? false : window.location.hostname === 'beta.bitcoin.me'

const MANAGER_API = process.env.REACT_APP_MANAGER_API

const MANAGER = axios.create({
	baseURL: MANAGER_API,
})

const MANAGER_AUTH = axios.create({
	baseURL: MANAGER_API,
	headers: { ClientOrigin: 3, 'Content-Type': '/application/json' },
})

MANAGER_AUTH.interceptors.request.use(async config => {
	config.headers.AuthToken = await getAccessToken()
	return config
})

// if (isProd || isBeta) {
// 	MANAGER_AUTH.interceptors.response.use(
// 		response => {
// 			return response
// 		},
// 		error => {
// 			if (error.response.status === 401) {
// 				keycloak.logout()
// 			}
// 			return error
// 		}
// 	)
// }

export const getVipLevelTable = async () => {
	try {
		const { data } = await MANAGER.get(`v1/get-vip-level-table`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getVipLevelTable logError')
		const errorStatus = get(error, 'response.status', 'error in getVipLevelTable getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/usersettings/getVipLevelTable', errorResponse)
		return { error: errorResponse }
	}
}

export const getVipLevelUser = async () => {
	try {
		const {
			data: { UserVipLevel },
		} = await MANAGER_AUTH.get(`v1/get-user-vip-level`)
		return UserVipLevel
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getVipLevelUser logError')
		const errorStatus = get(error, 'response.status', 'error in getVipLevelUser getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/usersettings/getVipLevelUser', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchKeyPairs = async Currency => {
	try {
		const { data } = await MANAGER.get(`v1/keypairs?Currency=${Currency}`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchKeyPairs logError')
		logError('klv_fetchKeyPairs_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchKeyPairsCard = async (
	KeypairsLimit = 1,
	TickersLimit = 24,
	TimeRange = 'HOUR_1',
	EnforceTimeRange = true,
	Featured = true,
	Currency
) => {
	try {
		const { data } = await MANAGER.get(
			`v1/keypairs-card?KeypairsLimit=${KeypairsLimit}&TickersLimit=${TickersLimit}&TimeRange=${TimeRange}&EnforceTimeRange=${EnforceTimeRange}&Currency=${Currency}&Featured=${Featured}`
		)
		return get(data, 'Keypairs', [])
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchKeyPairsCard logError')
		logError('klv_fetchKeyPairs_error', errorResponse)
		return { error: errorResponse }
	}
}

export const getOrders = async (keypairsID = 1, status, limit = ORDERS_LIMIT_QUANTITY, skip = 0) => {
	try {
		const query = isArray(status)
			? `v1/orders?Limit=${limit}&Skip=${skip}&KeypairID=${keypairsID}&Status=${status[0]}&Status=${status[1]}`
			: `v1/orders?Limit=${limit}&Skip=${skip}&KeypairID=${keypairsID}&Status=${
					status ? `&Status=${status}` : ''
			  }`

		const { data } = await MANAGER_AUTH.get(query)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getOrders logError')
		const errorStatus = get(error, 'response.status', 'error in getOrders getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getOrders_error', errorResponse)
		return { error: errorResponse }
	}
}

export const getStopOrders = async (keypairsID = 1, status, limit = ORDERS_LIMIT_QUANTITY, skip = 0) => {
	try {
		const query = isArray(status)
			? `v1/stop-orders?Limit=${limit}&Skip=${skip}&KeypairID=${keypairsID}&Status=${status[0]}&Status=${status[1]}`
			: `v1/stop-orders?Limit=${limit}&Skip=${skip}&KeypairID=${keypairsID}&Status=${
					status ? `&Status=${status}` : ''
			  }`

		const { data } = await MANAGER_AUTH.get(query)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getStopOrders logError')
		const errorStatus = get(error, 'response.status', 'error in getStopOrders getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getStopOrders_error', errorResponse)
		return { error: errorResponse }
	}
}

export const getOrderDetails = async OrderID => {
	try {
		const { data } = await MANAGER_AUTH.get(`v1/orderdetails?OrderID=${OrderID}`)
		return get(data, 'Details[0]', [])
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getOrderDetails logError')
		verifyExpired(errorResponse)
		logError('klv_getOrderDetails_error', errorResponse)
		return { error: errorResponse }
	}
}

export const getAllOrdersStatus = async (
	limit = 10,
	skip = 0,
	status,
	search = '',
	sort = 'UPDATEDAT',
	sortDirection = 'SORT_ORDER_TYPE_UNSPECIFIED',
	operation = ['LIMIT', 'MARKET'],
	side = ['SELL', 'BUY']
) => {
	try {
		let query = `v1/orders?Limit=${limit}&Skip=${skip}&Sort=${sort}&SortDirection=${sortDirection}&Search=${search}`

		if (isArray(operation)) {
			query = query + `&Operation=${operation}`
		}
		if (isArray(side)) {
			query = query + `&Side=${side}`
		}
		if (isArray(status)) {
			query = query + `&Status=${status[0]}&Status=${status[1]}`
		} else {
			query = query + `&Status=${status && status}`
		}

		const { data } = await MANAGER_AUTH.get(query)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getAllOrders logError')
		const errorStatus = get(error, 'response.status', 'error in getAllOrders getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getAllOrders_error', errorResponse)
		return { error: errorResponse }
	}
}

export const getConvertDust = async Tokens => {
	try {
		const response = await MANAGER_AUTH.post(`v1/convert-dust`, { Tokens })
		return get(response, 'Success', [])
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getConvertDust logError')
		logError('klv_getConvertDust_error')
		return { error: errorResponse }
	}
}

export const getConvertDustHistory = async ({ skip = 0, limit = 25, status = 'DONE' }) => {
	try {
		const response = await MANAGER_AUTH.get(`v1/convert-dust/history?Status=${status}&Limit=${limit}&Skip=${skip}`)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getConvertDustHistory logError')
		logError('klv_getConvertDustHistory_error')
		return { error: errorResponse }
	}
}

export const getAllOpenOrders = async (limit = 10, skip = 0, status, search, sort, sortDirection, side) => {
	try {
		let query = `v1/orders?Limit=${limit}&Skip=${skip}&Sort=${sort}&SortDirection=${sortDirection}&Search=${search}`

		if (isArray(side)) {
			query = query + `&Side=${side}`
		}
		if (isArray(status)) {
			query = query + `&Status=${status[0]}&Status=${status[1]}`
		} else {
			query = query + `&Status=${status && status}`
		}
		const response = await MANAGER_AUTH.get(query)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getAllOpenOrders logError')
		const errorStatus = get(error, 'response.status', 'error in getAllOpenOrders getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getAllOpenOrders_error', errorResponse)
		return { error: errorResponse }
	}
}

export const getAllStopOrders = async (limit = 10, skip = 0, status) => {
	try {
		const response = await MANAGER_AUTH.get(
			`v1/stop-orders?Status=${status[0]}&Status=${status[1]}&Limit=${limit}&Skip=${skip}`
		)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getAllStopOrders logError')
		const errorStatus = get(error, 'response.status', 'error in getAllStopOrders getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getAllStopOrders_error', errorResponse)
		return { error: errorResponse }
	}
}

export const getFills = async (keypairsID = 1) => {
	try {
		const { data } = await MANAGER.get(`v1/fills?KeypairID=${keypairsID}&Filter=TAKER`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getFills logError')
		logError('klv_getFills_error', errorResponse)
		return { error: errorResponse }
	}
}

export const getOrderGroups = async (id, decimals = 4, limit = 70, status) => {
	try {
		const query = isArray(status)
			? `v1/order-groups?KeypairID=${id}&Decimals=${decimals}&Limit=${limit}&Status=${status[0]}&Status=${status[1]}`
			: `v1/order-groups?KeypairID=${id}&Decimals=${decimals}&Limit=${limit}&Status=${status}`
		const { data } = await MANAGER.get(query)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getOrderGroups logError')
		logError('services/manager/getOrderGroups', errorResponse)
		return { error: errorResponse }
	}
}

export const getTokens = async (Currency = 'USD', isAuth = true, Name, NonFungible = false) => {
	try {
		let query = `v1/tokens?Currency=${Currency}&NonFungible=${NonFungible}`
		if (Name) {
			query = query + `&Name=${Name}`
		}

		let data = {}
		if (isAuth) {
			const response = await MANAGER_AUTH.get(query)
			data = response.data
		} else {
			const response = await MANAGER.get(query)
			data = response.data
		}
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getTokens logError')
		const errorStatus = get(error, 'response.status', 'error in getTokens getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/getTokens', errorResponse)
		return { error: errorResponse }
	}
}

export const getDeposits = async ({ limit = 20, skip = 0, search }, NonFungible = false) => {
	try {
		let query = `v1/deposits?Limit=${limit}&Skip=${skip}&NonFungible=${NonFungible}`
		if (search) {
			query = query + `&Search=${search}`
		}
		const { data } = await MANAGER_AUTH.get(query)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getDeposits logError')
		const errorStatus = get(error, 'response.status', 'error in getDeposits getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/getDeposits', errorResponse)
		return { error: errorResponse }
	}
}

export const getCalcMarket = async (amount, keypairID, side) => {
	try {
		const data = {
			KeypairID: keypairID,
			Side: side,
		}
		side === 'BUY' ? (data.QuoteAmount = amount) : (data.BaseAmount = amount)
		const response = await MANAGER_AUTH.post('v2/calc-market', data)
		return get(response, 'data', {})
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getCalcMarket logError')
		const errorStatus = get(error, 'response.status', 'error in getCalcMarket getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/getCalcMarket', errorResponse)
		return { error: errorResponse }
	}
}

export const getCalcMarketByInput = async (amount, input, side, keypairID) => {
	try {
		const data = {
			KeypairID: keypairID,
			Side: side,
		}
		input === 'amount' ? (data.BaseAmount = Number(amount)) : (data.QuoteAmount = Number(amount))
		const response = await MANAGER_AUTH.post('v2/calc-market', data)
		return get(response, 'data', {})
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getCalcMarket logError')
		const errorStatus = get(error, 'response.status', 'error in getCalcMarket getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/getCalcMarket', errorResponse)

		throw errorResponse
	}
}

export const getDepositsAddress = async networkID => {
	try {
		const { data } = await MANAGER_AUTH.get(`v1/deposit-address${networkID ? `?NetworkID=${networkID}` : ''}`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getDepositsAddress logError')
		const errorStatus = get(error, 'response.status', 'error in getDepositsAddress getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/getDeposits', errorResponse)
		return { error: errorResponse }
	}
}

export const getNetworks = async () => {
	try {
		const { data } = await MANAGER_AUTH.get(`v1/networks`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getNetworks logError')
		const errorStatus = get(error, 'response.status', 'error in getNetworks getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/getDeposits', errorResponse)
		return { error: errorResponse }
	}
}

export const getBalance = async TokenID => {
	try {
		const { data } = await MANAGER_AUTH.get(`v1/balances?TokenID=${TokenID}`)
		return get(data, 'Balances', [])
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getBalance logError')
		const errorStatus = get(error, 'response.status', 'error in getBalance getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/getBalance', errorResponse)
		return { error: errorResponse }
	}
}

export const getBalances = async () => {
	try {
		const { data } = await MANAGER_AUTH.get(`v1/balances`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getBalances logError')
		const errorStatus = get(error, 'response.status', 'error in getBalances getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/getBalances', errorResponse)
		return { error: errorResponse }
	}
}

export const cancelOrder = async payload => {
	try {
		const { data } = await MANAGER_AUTH.post(`v1/cancel-order`, payload)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in cancelOrder logError')
		const errorStatus = get(error, 'response.status', 'error in cancelOrder getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/cancelOrder', errorResponse)
		return { error: errorResponse }
	}
}

export const cancelStopOrder = async payload => {
	try {
		const { data } = await MANAGER_AUTH.post(`v1/cancel-stop-order`, payload)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in cancelStopOrder logError')
		const errorStatus = get(error, 'response.status', 'error in cancelStopOrder getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/cancelStopOrder', errorResponse)
		return { error: errorResponse }
	}
}

export const cancelAllOrders = async () => {
	try {
		const { data } = await MANAGER_AUTH.post(`v1/cancel-all-orders`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in cancelAllOrders logError')
		const errorStatus = get(error, 'response.status', 'error in cancelAllOrders getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/cancelAllOrders', errorResponse)
		return { error: errorResponse }
	}
}

export const cancelAllStopOrders = async () => {
	try {
		const { data } = await MANAGER_AUTH.post(`v1/cancel-all-stop-orders`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in cancelAllStopOrders logError')
		const errorStatus = get(error, 'response.status', 'error in cancelAllStopOrders getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/cancelAllStopOrders', errorResponse)
		return { error: errorResponse }
	}
}

export const cancelAllOrdersByKeypair = async KeypairID => {
	try {
		const { data } = await MANAGER_AUTH.post(`v1/cancel-all-orders?KeypairID=${KeypairID}`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in cancelAllOrdersByKeypair logError')
		const errorStatus = get(error, 'response.status', 'error in cancelAllOrdersByKeypair getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/cancelAllOrders', errorResponse)
		return { error: errorResponse }
	}
}

export const cancelAllStopOrdersByKeypair = async KeypairID => {
	try {
		const { data } = await MANAGER_AUTH.post(`v1/cancel-all-stop-orders`, { KeypairID })
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in cancelAllStopOrdersByKeypair logError')
		const errorStatus = get(error, 'response.status', 'error in cancelAllStopOrdersByKeypair getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/cancelAllStopOrdersByKeypair', errorResponse)
		return { error: errorResponse }
	}
}

export const getWithdrawals = async ({ limit = 20, skip = 0, NonFungible = '', search = '' }) => {
	try {
		let query = `v1/withdrawals?Limit=${limit}&Skip=${skip}&NonFungible=${NonFungible}`
		if (search) {
			query = query + `&Search=${search}`
		}

		const { data } = await MANAGER_AUTH.get(query)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getWithdrawals logError')
		const errorStatus = get(error, 'response.status', 'error in getWithdrawals getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/getWithdrawals', errorResponse)
		return { error: errorResponse }
	}
}

export const addWithdraw = async (amount, destination, network, token, factorTOTP) => {
	try {
		const { data } = await MANAGER_AUTH.post(
			`v1/withdraw`,
			{
				Amount: amount,
				Destination: destination,
				NetworkID: network,
				TokenID: token,
			},
			{
				headers: {
					FactorTOTP: factorTOTP,
				},
			}
		)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in addWithdraw logError')
		const errorStatus = get(error, 'response.status', 'error in addWithdraw getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/AddWithdraw', errorResponse)
		return { error: errorResponse }
	}
}

export const getAccountLimit = async (currency, type) => {
	try {
		const { data } = await MANAGER_AUTH.post(`v1/account-limit-info`, { Currency: currency, Type: type })
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in AccountLimitInfo logError')
		const errorStatus = get(error, 'response.status', 'error in AccountLimitInfo getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/AccountLimitInfo', errorResponse)
		return { error: errorResponse }
	}
}

export const getTokenBalance = async tokenID => {
	try {
		const { data } = await MANAGER_AUTH.get(`v1/balances?TokenID=${tokenID}`)
		return get(data, 'Balances.[0]', [])
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getTokenBalance logError')
		const errorStatus = get(error, 'response.status', 'error in getTokenBalance getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_services/manager/getTokenBalance_error', errorResponse)
		return { error: errorResponse }
	}
}

export const limitOrder = async payload => {
	try {
		const data = await MANAGER_AUTH.post('v1/limit-order', payload)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', error?.message)
		const errorStatus = get(error, 'response.status', 'error in limitOrder getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_placeLimit_error', errorResponse)
		return { error: errorResponse }
	}
}

export const marketOrder = async payload => {
	try {
		const data = await MANAGER_AUTH.post('v2/market-order', payload)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in marketOrder logError')
		const errorStatus = get(error, 'response.status', 'error in marketOrder getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_marketOrder_error', errorResponse)
		return { error: errorResponse }
	}
}

export const stopLimitOrder = async payload => {
	try {
		const data = await MANAGER_AUTH.post('v1/stop-limit-order', payload)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', error?.message)
		const errorStatus = get(error, 'response.status', 'error in stopLimitOrder getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_stopLimitOrder_error', errorResponse)
		return { error: errorResponse }
	}
}

export const stopMarketOrder = async payload => {
	try {
		const data = await MANAGER_AUTH.post('v1/stop-market-order', payload)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', error?.message)
		const errorStatus = get(error, 'response.status', 'error in stopMarketOrder getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_stopMarketOrder_error', errorResponse)
		return { error: errorResponse }
	}
}

export const fetchTickers = async (keypairID = 0, timeRange = 'DAILY', periodParams) => {
	try {
		let params = {
			KeypairID: keypairID,
			Limit: 1000,
			TimeRange: timeRange,
		}

		const tenDays = 864000000

		params = {
			...params,
			Limit: periodParams?.countBack + 1,
			'TimeInterval.Begin': new Date((periodParams.from - tenDays) * 1000),
			'TimeInterval.Ends': new Date(periodParams.to * 1000),
		}

		// if (new Date(periodParams.from * 1000 - tenDays).getTime() < 1633057200000) {
		// 	return []
		// }

		const response = await MANAGER.get(`v1/tickers`, { params })
		return get(response, 'data.Tickers', [])
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchTickers logError')
		const errorStatus = get(error, 'response.status', 'error in fetchTickers getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_fetchTickers_error', errorResponse)
		return errorResponse
	}
}

export const getOrderbookStatus = async (keypairID = 0) => {
	try {
		const response = await MANAGER.get(`v1/obstatus?KeypairID=${keypairID}`)
		return get(response, 'data', null)
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchOrderbookStatus logError')
		const errorStatus = get(error, 'response.status', 'error in fetchOrderbookStatus getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_fetchOrderbookStatus_error', errorResponse)
		return errorResponse
	}
}

export const fetchKycStatus = async () => {
	try {
		const { data } = await MANAGER_AUTH.post(`v2/get-status-kyc`)
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in fetchKycStatus logError')
		const errorStatus = get(error, 'response.status', 'error in fetchKycStatus post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_fetchKycStatus_error', errorResponse)
		return { error: errorResponse }
	}
}

export const initiateKYC = async () => {
	try {
		const response = await MANAGER_AUTH.post(`v1/initiate-kyc`)
		return get(response, 'data', null)
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in initiateKYC logError')
		const errorStatus = get(error, 'response.status', 'error in initiateKYC post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_initiateKYC_error', errorResponse)
		return { error: errorResponse }
	}
}

export const updateKYC = async data => {
	try {
		const response = await MANAGER_AUTH.post(`v1/update-status-kyc`, data)
		return get(response, 'data', null)
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in updateKYC logError')
		const errorStatus = get(error, 'response.status', 'error in updateKYC post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_updateKYC_error', errorResponse)
		return { error: errorResponse }
	}
}

export const createApiKey = async (data, FactorTOTP) => {
	try {
		const response = await MANAGER_AUTH.post(
			`v1/generate-key`,
			{ ...data, MaxAmount: 1000000 },
			{
				headers: {
					FactorTOTP,
				},
			}
		)
		return get(response, 'data', null)
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in createApiKey logError')
		const errorStatus = get(error, 'response.status', 'error in createApiKey post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_createApiKey_error', errorResponse)
		return { error: errorResponse }
	}
}

export const deleteApiKey = async (label, factorTOTP) => {
	try {
		const response = await MANAGER_AUTH.post(
			`v1/delete-key`,
			{ Label: label },
			{
				headers: {
					factorTOTP,
				},
			}
		)
		return get(response, 'data.Success', null)
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in deleteApiKey logError')
		const errorStatus = get(error, 'response.status', 'error in deleteApiKey post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_deleteApiKey_error', errorResponse)
		return errorResponse
	}
}

export const getApiKeys = async () => {
	try {
		const response = await MANAGER_AUTH.post(`v1/apikeys`)
		return get(response, 'data.Keys', [])
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getApiKeys logError')
		const errorStatus = get(error, 'response.status', 'error in getApiKeys post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getApiKeys_error', errorResponse)
		return errorResponse
	}
}

export const addIP = async (label, ip, FactorTOTP) => {
	try {
		const response = await MANAGER_AUTH.get(`v1/add-ip?Label=${label}&AddressIP=${ip}`, {
			headers: {
				FactorTOTP,
			},
		})
		return get(response, 'data.IP', null)
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in addIP logError')
		const errorStatus = get(error, 'response.status', 'error in addIP post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_addIP_error', errorResponse)
		return { error: errorResponse }
	}
}

export const deleteIP = async (id, FactorTOTP) => {
	try {
		const response = await MANAGER_AUTH.get(`v1/delete-ip?ID=${id}`, {
			headers: {
				FactorTOTP,
			},
		})
		return get(response, 'data.Success', null)
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in deleteIP logError')
		const errorStatus = get(error, 'response.status', 'error in deleteIP post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_deleteIP_error', errorResponse)
		return { error: errorResponse }
	}
}

export const getToken = async (Currency = 'BRL', tokenId) => {
	try {
		let data = {}
		const response = await MANAGER.get(`v1/tokens?Currency=${Currency}&ID=${tokenId}`)
		data = response.data
		return get(data, 'Tokens', [])
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getToken logError')
		const errorStatus = get(error, 'response.status', 'error in getToken getStatus')
		verifyExpired(errorResponse, errorStatus)
		logError('services/manager/getToken', errorResponse)
		return { error: errorResponse }
	}
}

export const getIPList = async label => {
	try {
		const response = await MANAGER_AUTH.get(`v1/get-ip-list?Label=${label}`)
		return get(response, 'data.IP', [])
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getIPList logError')
		const errorStatus = get(error, 'response.status', 'error in getIPList post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getIPList_error', errorResponse)
		return errorResponse
	}
}

export const getEstimatedDust = async (currency = 'USD') => {
	try {
		const response = await MANAGER_AUTH.get(`v1/estimated/convert-dust?Currency=${currency}`)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getEstimatedDust logError')
		const errorStatus = get(error, 'response.status', 'error in getEstimatedDust get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getEstimatedDust_error', errorResponse)
		return errorResponse
	}
}

export const getUserConversion = async () => {
	try {
		const response = await MANAGER_AUTH.get(`v1/get-user-conversion`)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getUserConversion logError')
		const errorStatus = get(error, 'response.status', 'error in getUserConversion get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getUserConversion_error', errorResponse)
		return errorResponse
	}
}

export const getRankingCompetition = async data => {
	try {
		const response = await MANAGER.get(`v1/top-traders`, { params: data })
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getRankingCompetition logError')
		const errorStatus = get(error, 'response.status', 'error in getRankingCompetition get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getRankingCompetition_error', errorResponse)
		return errorResponse
	}
}

export const getExchangeWallets = async () => {
	try {
		const response = await MANAGER.get(`v1/list-exchange-wallets`)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getExchangeWallets logError')
		const errorStatus = get(error, 'response.status', 'error in getExchangeWallets get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getExchangeWallets_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const getWalletAddresses = async ({ limit = 20, skip = 0, search = '', networkName = '' }) => {
	try {
		const response = await MANAGER.get(
			`v1/list-wallet-addresses?limit=${limit}&skip=${skip}&search=${search}&networkName=${networkName}`
		)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getWalletAddresses logError')
		const errorStatus = get(error, 'response.status', 'error in getWalletAddresses get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getWalletAddresses_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const getExchangeBalances = async () => {
	try {
		const response = await MANAGER.get('v1/list-exchange-balances')
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getExchangeBalances logError')
		const errorStatus = get(error, 'response.status', 'error in getExchangeBalances get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getExchangeBalances_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const getAllBfs = async () => {
	try {
		const response = await MANAGER.get('v1/get-bfs?isWeb=true')
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getAllBfs logError')
		const errorStatus = get(error, 'response.status', 'error in getAllBfs get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getAllBfs_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const getBfs = async ({ bfsId }) => {
	try {
		const response = await MANAGER.get(`v1/bfs-details?bfsId=${bfsId}`)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getBfs logError')
		const errorStatus = get(error, 'response.status', 'error in getBfs get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getBfs_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const getBfsAcceptableTokens = async ({ bfsId }) => {
	try {
		const response = await MANAGER_AUTH.get(`v1/bfs-acceptable-tokens?bfsId=${bfsId}`)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getBfsAcceptableTokens logError')
		const errorStatus = get(error, 'response.status', 'error in getBfsAcceptableTokens get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getBfsAcceptableTokens_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const buyBfs = async ({ amount, tokenId, address, periodId, rebateId, cost }) => {
	try {
		const reponse = await MANAGER_AUTH.post('v1/buy-bfs', {
			amount,
			tokenId,
			address,
			periodId,
			rebateId,
			cost,
		})
		return reponse?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in buyBfs logError')
		const errorStatus = get(error, 'response.status', 'error in buyBfs get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_buyBfs_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const getBfsHistory = async ({ limit = 20, skip = 0, status = '' }) => {
	try {
		const response = await MANAGER_AUTH.get(`v1/bfs-history?limit=${limit}&skip=${skip}&status=${status}`)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getBfsHistory logError')
		const errorStatus = get(error, 'response.status', 'error in getBfsHistory get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getBfsHistory_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const claimBfs = async ({ collectionId, nftHash }) => {
	try {
		const response = await MANAGER_AUTH.post(`v1/claim-bfs`, { collectionId, nftHash })
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in claimBfs logError')
		const errorStatus = get(error, 'response.status', 'error in claimBfs get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_claimBfs_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const quotationBfs = async ({ bfsId, tokenId, amount }) => {
	if (!bfsId || !tokenId || !amount) return

	try {
		const response = await MANAGER.get(`v1/bfs-quotation?bfsId=${bfsId}&tokenId=${tokenId}&amount=${amount}`)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in quotationBfs logError')
		const errorStatus = get(error, 'response.status', 'error in quotationBfs get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_quotationBfs_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const getConvertTokens = async ({ currency, isAuth }) => {
	if (!currency) return

	try {
		let data = {}
		if (isAuth) {
			const response = await MANAGER_AUTH.get(`v1/convert-tokens?currency=${currency}`)
			data = response.data
		} else {
			const response = await MANAGER.get(`v1/convert-tokens?currency=${currency}`)
			data = response.data
		}
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getConvertTokens logError')
		const errorStatus = get(error, 'response.status', 'error in getConvertTokens get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getConvertTokens_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const getConvertKeypairs = async ({ baseTokenId, baseChainId, currency, isAuth }) => {
	try {
		let data = {}
		if (isAuth) {
			const response = await MANAGER_AUTH.get(
				`v1/convert-keypairs?baseTokenId=${baseTokenId}&baseChainId=${baseChainId}&currency=${currency}`
			)
			data = response.data
		} else {
			const response = await MANAGER.get(
				`v1/convert-keypairs?baseTokenId=${baseTokenId}&baseChainId=${baseChainId}&currency=${currency}`
			)
			data = response.data
		}
		return data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getConvertKeypairs logError')
		const errorStatus = get(error, 'response.status', 'error in getConvertKeypairs get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getConvertKeypairs_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const getConvertHistory = async ({ limit = 100, skip = 0, side, status }) => {
	try {
		if (side === 'all' && status === 'all') {
			const response = await MANAGER_AUTH.get(`v1/convert-history?limit=${limit}&skip=${skip}`)
			return response?.data
		}

		if (side === 'all') {
			const response = await MANAGER_AUTH.get(
				`v1/convert-history?limit=${limit}&skip=${skip}&status=${status?.toUpperCase()}`
			)
			return response?.data
		}

		if (status === 'all') {
			const response = await MANAGER_AUTH.get(
				`v1/convert-history?limit=${limit}&skip=${skip}&side=${side?.toUpperCase()}`
			)
			return response?.data
		}

		const response = await MANAGER_AUTH.get(
			`v1/convert-history?limit=${limit}&skip=${skip}&side=${side?.toUpperCase()}&status=${status?.toUpperCase()}`
		)
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getConvertHistory logError')
		const errorStatus = get(error, 'response.status', 'error in getConvertHistory get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getConvertHistory_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const getConvertQuotation = async ({
	keypairID,
	baseAssetID,
	baseChainID,
	quoteAssetID,
	quoteChainID,
	amount,
	side,
	inverse,
}) => {
	if (!keypairID || !baseAssetID || !baseChainID || !quoteAssetID || !quoteChainID || !amount || !side) return

	try {
		const response = await MANAGER_AUTH.post(`/v1/convert-quotation`, {
			keypairID,
			baseAssetID: String(baseAssetID),
			baseChainID: String(baseChainID),
			quoteAssetID: String(quoteAssetID),
			quoteChainID: String(quoteChainID),
			amount,
			side,
			inverse,
		})
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getConvertQuotation logError')
		const errorStatus = get(error, 'response.status', 'error in getConvertQuotation get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getConvertQuotation_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const executeConvert = async ({ swapKeypairID, slippage, quotationID, side, amount }) => {
	if (!swapKeypairID || !slippage || !quotationID || !side || !amount) return

	try {
		const response = await MANAGER_AUTH.post(`/v1/execute-convert`, {
			swapKeypairID,
			slippage,
			quotationID,
			side,
			amount,
		})
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in executeConvert logError')
		const errorStatus = get(error, 'response.status', 'error in executeConvert get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_executeConvert_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const deleteUserAccount = async ({ totp }) => {
	try {
		const response = await MANAGER_AUTH.get(`/v1/delete-user-account`, {
			headers: {
				FactorTOTP: totp,
			},
		})
		return response?.data
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in deleteUserAccount logError')
		const errorStatus = get(error, 'response.status', 'error in deleteUserAccount get')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_deleteUserAccount_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const getPassKeys = async () => {
	try {
		const response = await MANAGER_AUTH.get(`v1/passwordless-keys`)
		return get(response, 'data.securityKeys', [])
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in getPassKeys logError')
		const errorStatus = get(error, 'response.status', 'error in getPassKeys post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_getPassKeys_error', errorResponse)
		return errorResponse
	}
}

export const createPassKey = async () => {
	try {
		await MANAGER_AUTH.post(`v1/passwordless-register`)
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in createPassKey logError')
		const errorStatus = get(error, 'response.status', 'error in createPassKey post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_createPassKey_error', errorResponse)
		return { error: errorResponse }
	}
}

export const deletePassKey = async credentialId => {
	try {
		await MANAGER_AUTH.delete(`v1/passwordless-delete`, {
			data: { credentialId },
		})
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in deletePassKey logError')
		const errorStatus = get(error, 'response.status', 'error in deletePassKey post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_deletePassKey_error', errorResponse)
		throw new Error(errorResponse)
	}
}

export const accelerateDeposit = async ({ tokenId, chainId, blockchainHash }) => {
	if (!tokenId || !chainId || !blockchainHash) return
	let finalHash = blockchainHash

	if (chainId === '1') {
		finalHash = `0x${blockchainHash}`
	}

	try {
		await MANAGER_AUTH.post(`v1/accelerate-deposit`, {
			tokenId,
			chainId,
			blockchainHash: finalHash,
		})
	} catch (error) {
		const errorResponse = get(error, 'response.data.message', 'error in accelerateDeposit logError')
		const errorStatus = get(error, 'response.status', 'error in accelerateDeposit post')
		verifyExpired(errorResponse, errorStatus)
		logError('klv_accelerateDeposit_error', errorResponse)
		throw new Error(errorResponse)
	}
}
