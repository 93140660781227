/* eslint-disable no-useless-return */
/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import axios from 'axios'
import BigNumber from 'bignumber.js'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import replace from 'lodash/replace'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import numeral from 'numeral'
import qs from 'qs'

import { contracts } from '../__mocks__/contracts'
import { STORAGE_TOKENS } from '../constants'
import keycloak from '../keycloak'
import { fetchNFTMetadata } from '../services/nft'
import { MESSAGE_EXPIRED, MINIMUM } from './constants'
import { excludeInputChars } from './keyboardKeys'

const defaultDecimals = 6
const TOKEN = '@klevershop/token'
const NFTS_SOFT = '@klevershop/NFTsSoft'

const HASMFAENABLED = 'SA876DS87D6ASD'
const USERCURRENCY = 'klever/currency'
const TEST = process.env.NODE_ENV === 'test'

export const setHasMFAEnabled = hasMfaEnabled => {
	localStorage.setItem(HASMFAENABLED, hasMfaEnabled)
}
export const setUserCurrency = currency => {
	localStorage.setItem(USERCURRENCY, currency)
}

// JSON.parse(localStorage.getItem('klever/currency')) || {
// 			id: '1',
// 			symbol: '$',
// 			name: 'USD',
// 		}

export const getUserCurrency = () => {
	// return JSON.parse(localStorage.getItem(USERCURRENCY) || '{}')
	const currency = localStorage.getItem('klever/currency')
	if (currency) {
		return JSON.parse(currency)
	}

	return {
		id: '1',
		symbol: '$',
		name: 'USD',
	}
}

export const getHasMFAEnabled = () => {
	return JSON.parse(localStorage.getItem(HASMFAENABLED) || '{}')
}

export const currencyToNumber = (value = '') => {
	return Number(value?.replace(/[^0-9.-]+/g, ''))
}

export const cleanBlankSpaces = value => {
	if (value?.toString()?.includes(' ')) {
		return value?.replace(/[ ]/gi, '')
	}
	return value
}

export const orderbookLength = (orderType, screenHeight) => {
	if (orderType === 'both') {
		return parseInt((screenHeight * 0.42) / 2 / 18)
	} else {
		return parseInt((screenHeight * 0.42) / 2 / 8)
	}
}

export const logError = (component = 'Component not defined', error = 'Error not defined') => {
	if (!TEST && !isProd()) {
		console.log(component, error)
	}
}

export const verifyExpired = (message, status) => {
	if (status === 401) {
		if (message === MESSAGE_EXPIRED) {
			window.location.reload()
		}
	}
}

export const calculePorcent = (value, byValue) => {
	return BigNumber(value || 0)
		.multipliedBy(100)
		.dividedBy(byValue || 0)
		.toFixed(2)
}

export const calculeDiferenc = (value, byValue, decimals = 2) => {
	return parseFloat(value - byValue).toFixed(decimals)
}

export const isAuthenticated = () => {
	const authUser = keycloak?.tokenParsed

	return !isEmpty(authUser)
}

export const formattedText = text => {
	if (text?.length > 0) {
		return `${text?.slice(0, 6)}...${text?.slice(text?.length - 6)}`
	}
}

export const tokenAbbr = (id, tokens) => {
	const token = tokens?.find(token => token?.ID === id)
	return token?.Abbr
}

export const isFromCurrentUser = userId => {
	const currentUser = keycloak?.tokenParsed
	if (isEmpty(currentUser)) return false
	return currentUser?.sub === userId
}

export const formatPrice = priceToFormat => {
	return priceToFormat.toFixed(4)
}

export const getZeros = (precision = 1) => {
	let string = ''
	if (precision > 0) {
		for (let index = 0; index < precision; index++) {
			string += '0'
		}
		return string
	}
	return ''
}

export const formatDate = dateToFormat => {
	const date = new Date(dateToFormat)
	return `${(date.getHours() < 10 ? '0' : '') + date.getHours()}:${
		(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
	}:${(date.getSeconds() < 10 ? '0' : '') + date.getSeconds()}`
}

export const definePrecision = (value, precision = 2, ignoreMinPrecision = false) => {
	try {
		if (value >= 0) {
			switch (true) {
				case value === 0:
					return ignoreMinPrecision ? precision : 2
				case value < 10:
					return precision < 10 ? precision : 10
				case value >= 100 && value < 1000:
					return precision < 6 ? precision : 5
				case value >= 1000 && value < 10000:
					return precision < 4 ? precision : 3
				case value >= 10000:
					return precision < 3 ? precision : 2
				default:
					return Math.min(precision, 10)
			}
		} else {
			return Math.min(precision, 10)
		}
	} catch (e) {
		return 2
	}
}

const formatInteger = numberStr => numberStr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '')

export const formatNumber = (
	number,
	precision = 0,
	intPrecision = true,
	short = false,
	fromOder = false,
	singleZero = false
) => {
	try {
		let bigNumber = new BigNumber(number)
		const minNumberToFormat = fromOder ? 100000 : 1000
		if (bigNumber >= minNumberToFormat && short) {
			return shortNumber(bigNumber)
		}

		const isNegative = bigNumber.isNegative()
		bigNumber = bigNumber.absoluteValue()
		const numberZero = intPrecision ? (!precision ? '0' : `0.${getZeros(precision)}`) : '0'

		if (!bigNumber || bigNumber.isEqualTo(0) || bigNumber.isNaN()) {
			return numberZero
		}

		if (bigNumber.isInteger()) {
			const signal = isNegative ? '-' : ''
			return `${signal}${formatInteger(bigNumber)}${
				intPrecision ? (!precision ? '' : `.${getZeros(precision)}`) : ''
			}`
		}

		const decimalPosition = definePrecision(bigNumber, precision)
		if (bigNumber < MINIMUM) {
			return numberZero
		}
		let splitedBigNumber = bigNumber.toFixed().split('.')
		if (splitedBigNumber?.length < 2) {
			splitedBigNumber = [...splitedBigNumber, '00']
		}

		let result = !decimalPosition
			? splitedBigNumber.reduce(first => `${formatInteger(first)}`)
			: splitedBigNumber.reduce((first, second) => `${formatInteger(first)}.${second.substr(0, decimalPosition)}`)

		if (
			singleZero &&
			splitedBigNumber?.[1]?.length === 1 &&
			splitedBigNumber?.[1] > 0 &&
			splitedBigNumber?.[1] < 10 &&
			BigNumber(splitedBigNumber?.[1])?.isInteger()
		) {
			result = result + '0'
		}

		return isNegative ? `-${result}` : result
	} catch (e) {
		logError('tw_formatNumber', e)
		if (number) {
			return new BigNumber(number).toFixed(precision || 2, 1)
		}
		return '0.00'
	}
}

export const addZeros = ({
	number = 0,
	precision = 0,
	addZerosWhenZero = false,
	noForse = false,
	justFormat = false,
	ignoreMinPrecision = false,
	fromOrder = false,
	short = false,
	intPrecision = false,
	mimPrecision = false,
	forseZeros = false,
	noFormatInteger = false,
	integerFromOrderbook = false,
}) => {
	const bigNumber = new BigNumber(number || 0)
	const fixPrecision = definePrecision(number, precision, ignoreMinPrecision)
	let newNumber = formatNumber(number, fixPrecision)
	const splitedBigNumber = bigNumber.toFixed().split('.')
	const minNumberToFormat = fromOrder ? 1_000_000 : 1000
	const isNegative = bigNumber.isNegative()

	if (newNumber?.split('.')?.[1]?.length < precision && forseZeros) {
		for (let index = newNumber?.split('.')?.[1]?.length; index < precision; index++) {
			newNumber += '0'
		}
	} else if (forseZeros) {
		newNumber = newNumber.slice(0, newNumber?.split('.')?.[0]?.length + 1 + precision)
	}

	if (bigNumber >= minNumberToFormat && short) {
		return shortNumber(bigNumber)
	}

	if (bigNumber.isInteger() && integerFromOrderbook) {
		return `${formatInteger(bigNumber)}${!precision ? '' : `.${getZeros(precision)}`}`
	}

	if (bigNumber.isInteger()) {
		const signal = isNegative ? '-' : ''
		return noFormatInteger
			? formatInteger(bigNumber)
			: `${signal}${formatInteger(bigNumber)}${intPrecision ? '.00' : `.${getZeros(precision)}`}`
	}

	if (newNumber?.split('.')?.[1]?.length >= fixPrecision && !forseZeros) {
		let minimum = '0.'
		for (let index = 0; index < fixPrecision - 1; index++) {
			minimum += '0'
		}
		const minimumNumber = BigNumber(minimum + '1')

		if (bigNumber.toFixed() < minimumNumber.toFixed() && bigNumber.toFixed() < 1) {
			const result = ignoreMinPrecision
				? removeLatestZeros(bigNumber.toFixed(11).replace(/\.0+$/, ''))
				: removeLatestZeros(minimumNumber.toFixed(11).replace(/\.0+$/, ''))

			if (mimPrecision) {
				return '0.00'
			}
			return result
		}
	} else if (!(BigNumber(splitedBigNumber?.[1]).toFixed() > 0) && noForse) {
		return newNumber
	}

	if (newNumber?.split('.')?.[1]?.length < fixPrecision) {
		let string = newNumber
		for (let index = 0; index < fixPrecision - newNumber?.split('.')?.[1]?.length; index++) {
			string += '0'
		}
		return justFormat ? newNumber : string
	} else if (!newNumber?.split('.')?.[1]?.length) {
		let string = newNumber
		for (let index = 0; index < fixPrecision - 2; index++) {
			string += '0'
		}

		if (!!(newNumber === '0.00' || newNumber === 0 || newNumber === '0') && addZerosWhenZero) {
			let string = newNumber
			for (let index = 0; index < precision - 2; index++) {
				string += '0'
			}
			return string
		}
		return justFormat ? newNumber : string
	} else {
		if (newNumber === '0.00' || newNumber === 0 || newNumber === '0') {
			let string = newNumber
			for (let index = 0; index < fixPrecision - 2; index++) {
				string += '0'
			}
			return justFormat ? newNumber : string
		}
		return newNumber
	}
}

export const rawPriceFormat = (number = 0, precision = 0) => {
	return BigNumber(number).toFixed(precision, 1)
}

export const removeLatestZeros = (string = '') => {
	try {
		const reverse = string.split('').reverse().join('')

		const removedZeros = reverse.replace(/^0+/, '')

		if (isEmpty(removedZeros) || removedZeros[0] === '.') {
			return string
		}
		return removedZeros.split('').reverse().join('')
	} catch (e) {
		return string
	}
}

export const shortNumber = (num = '', decimal = 2) => {
	const number = Number(num)

	if (number >= 1000000000000) {
		return (number / 1000000000000).toFixed(decimal).replace(/\.0$/, '') + 'T'
	}
	if (number >= 1000000000) {
		return (number / 1000000000).toFixed(decimal).replace(/\.0$/, '') + 'B'
	}
	if (number >= 1000000) {
		return (number / 1000000).toFixed(decimal).replace(/\.0$/, '') + 'M'
	}
	if (number >= 1000) {
		return (number / 1000).toFixed(decimal).replace(/\.0$/, '') + 'K'
	}
	return number.toFixed(decimal)
}

export const secondsToDateTime = secs => {
	const time = new Date(1970, 0, 1)
	time.setSeconds(secs)
	time.setHours(time.getHours() - 3)
	return time.toISOString()
}

export const decimalsBiggerThanPrecision = (value, precision) => {
	return value?.toString()?.split('.')?.[1]?.length > precision
}

export const sortArray = (array, field) => {
	if (isArray(array)) {
		const response = array?.sort(function (a, b) {
			return a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0
		})
		return response
	} else {
		return array
	}
}
export const sortArrayDecreasing = (array, field) => {
	if (isArray(array)) {
		const response = array?.sort(function (a, b) {
			return a[field] > b[field] ? -1 : a[field] < b[field] ? 1 : 0
		})
		return response
	} else {
		return array
	}
}

export const sortArrayDecreasingByBigNumbers = (array, field) => {
	if (isArray(array)) {
		const response = array?.sort(function (a, b) {
			return -BigNumber(a[field] || 0).comparedTo(BigNumber(b[field] || 0))
		})
		return response
	} else {
		return array
	}
}

export const sortArrayByBoolean = (array, field) => {
	if (isArray(array)) {
		const response = array?.sort((a, b) => b[field] - a[field])
		return response
	} else {
		return array
	}
}

export const takeLast = () => {}

export const formatNumberOneToTen = number => {
	if (number > 0 && number < 10) {
		return ('0' + number)?.slice(-2)
	}
	return number
}

export const getContractScanUrl = (address, blockchainName) => {
	switch (`${blockchainName}`.toUpperCase()) {
		case 'TRX':
			return `https://tronscan.org/#/contract/${address}`
		case 'ETH':
			return `https://etherscan.io/address/${address}`
		case 'KLV':
			return `https://kleverscan.org/account/${address}`
		default:
			return `https://tronscan.org/#/contract/${address}`
	}
}

export const convertStringToNumberAndFormart = (value, precision) => {
	return numeral(formatNumber(value, precision))?._value || 0
}

export const getAddressScanUrl = (address, networkID) => {
	switch (networkID) {
		case 1:
			return `https://www.blockchain.com/btc/address/${address}`
		case 3:
			return `https://etherscan.io/address/${address}`
		case 5:
			return `https://blockbook-dogecoin.tronwallet.me/address/${address}`
		case 70:
			return `https://www.blockchain.com/bch/address/${address}`
		case 77:
			return `https://kleverscan.org/account/${address}`
		default:
			return `https://tronscan.org/#/address/${address}`
	}
}

export const translateMessage = (message, type, messageDefault) => {
	const string = message?.split(': ')
	const lastString = string?.[string?.length - 1]
	// messages errors
	// "this method requires authentication"
	// "order: missing parameter"
	// "controller: keypair not found"
	// "controller: missing parameter"
	// "this keypair is not active to trade, try again later"
	// "precision has exceeded the maximum allowed to this keypair on quantity property"
	// "precision has exceeded the maximum allowed to this keypair on price property"
	// "unauthorized transaction"
	// "controller: token not found"
	// "invalid arguments"
	// "controller: failed to authorize limit order: the user cannot fill his own order"
	// "controller: account not found"
	// "controller: this account is not active"
	// "controller: balance not found"
	// "controller: insufficient available balance"
	// "unauthorized transaction"
	// "address not valid, belongs to the exchange"

	switch (lastString) {
		case 'the user cannot fill his own order':
			// klv_placeLimit_error Failed to create a limit order: Failed to authorize side: controller: failed to authorize limit order: the user cannot fill his own order
			return 'the_user_cannot_fill_his_own_order'
		case 'context deadline exceeded':
			// Failed to cancel all orders: rpc error: code = Internal desc = Failed to get all user orders: Failed get orders from DB: context deadline exceeded
			return 'context_deadline_exceeded'
		case 'active orders not found':
			// Failed to create a market order: Failed to calculate market order price: orderbook: active orders not found
			return 'active_orders_not_found'
		case 'the user has an open order on the opposing side':
			// Failed to create a market order: Failed to authorize side: controller: failed to authorize market order: the user has an open order on the opposing side
			return 'the_user_has_an_open_order_on_the_opposing_side'
		case 'the user has an open market order on the opposing side':
			// failed to create a market order: orders repository: failed to authorize side: the user has an open market order on the opposing side
			return 'the_user_has_an_open_limit_order_on_the_opposing_side'
		case 'this method requires authentication':
			return 'this_method_requires_authentication'
		case 'missing parameter':
			return 'missing_parameter'
		case 'keypair not found':
			return 'keypair_not_found'
		case 'this keypair is not active to trade, try again later':
			return 'this_keypair_is_not_active_trade'
		case 'precision has exceeded the maximum allowed to this keypair on quantity property':
			return 'precision_has_exceeded_the_maximum_allowed_to_this_keypair_on_quantity_property'
		case 'precision has exceeded the maximum allowed to this keypair on price property':
			return 'precision_has_exceeded_the_maximum_allowed_to_this_keypair_on_price_property'
		case 'unauthorized transaction':
			return 'unauthorized_transaction'
		case 'token not found':
			return 'token_not_found'
		case 'invalid arguments':
			return 'invalid_arguments'
		case 'failed to authorize limit order: the user cannot fill his own order':
			return 'failed_to_authorize_limit_order:_the_user_cannot_fill_his_own_order'
		case 'account not found':
			return 'account_not_found'
		case 'this account is not active':
			return 'this_account_is_not_active'
		case 'balance not found':
			return 'balance_not_found'
		case 'insufficient available balance':
			return 'insufficient_available_balance'
		case 'token id not found':
			// message: "Withdraw failed: rpc error: code = Internal desc = Withdraw failed: rpc error: code = Internal desc = Withdraw failed: Failed to get token converted from cache: Failed to update token converted from cache: Failed converting this amount to usd: utils: token id not found"
			return 'token_id_not_found'

		case 'not_possible_to_cancel':
			return 'not_possible_to_cancel'
		case 'withdraw_create_error':
			return 'withdraw_create_error'
		case 'insufficient balance':
			return 'insufficient_available_balance'
		case 'tokenID is not valid for this collection':
			return 'token_id_is_not_valid_for_this_collection'
		case 'nft not found':
			return 'nft_not_found'
		case 'min offer price bigger than price':
			return 'min_offer_price_bigger_than_price'
		case 'Network Error':
			return 'connection_failure'
		case 'this order is already closed':
			return 'this_order_is_already_closed'
		case 'average price 25% higher then last price':
			return 'different_from_the_current_price'
		case 'average price 25% lower then last price':
			return 'different_from_the_current_price_lower'
		case 'address not valid, belongs to the exchange':
			return 'address_not_valid_belongs_to_the_exchange'

		default:
			const response = messageDefault || (type === 'withdraw' ? 'withdraw_create_error' : 'orderError')
			return response
	}
}

export const isValidNumber = e => {
	if (e?.key !== '+' && e?.key !== '-' && e?.key !== 'e' && e?.key !== 'E') {
		return false
	} else {
		return true
	}
}

export const capitalizeFirstLetter = (input = '') => {
	if (typeof input !== 'string' || input.length === 0) {
		return input
	}
	return input.charAt(0).toUpperCase() + input.toLowerCase()?.slice(1)
}

export const splitName = (name, inverse) => {
	const newName = name?.split('-')
	return inverse ? newName?.[1] : newName?.[0]
}

export const starStyle = { color: '#d96200', paddingLeft: 10 }

export const greyStyle = { color: '#424665' }

export const getAddress = (address, quantity = 15, end = -3) => {
	if (address?.length > 0) return `${address?.substr(0, quantity)}...${address?.slice(end)}`
}

export const translateStatus = status => {
	switch (status) {
		case 'DONE':
			return 'done'
		case 'PROGRESS':
			return 'progress'
		case 'DENIED':
			return 'denied'
		case 'CANCELED':
			return 'canceled'
		case 'PARTIALLY':
			return 'partially'
		case 'OPEN':
			return 'open'
		case 'ERROR':
			return 'error'
		case 'PENDING':
			return 'pending'

		default:
			return status
	}
}

export const translateStatusForColor = status => {
	switch (status) {
		case 'BUY':
			return '#37DD72'
		case 'DONE':
			return '#37DD72'
		case 'PROGRESS':
			return '#F4C039'
		case 'DENIED':
			return '#F43942'
		case 'SELL':
			return '#F43942'
		case 'CANCELED':
			return '#F43942'
		case 'PARTIALLY':
			return '#F4C039'
		case 'OPEN':
			return '#37DD72'
		case 'ERROR':
			return '#F43942'
		case 'PENDING':
			return '#F4C039'

		default:
			return '#F4C039'
	}
}

export const formatCurrency = (currency = 'USD', value = 0) => {
	switch (currency) {
		case 'BRL':
			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency,
			}).format(value)

		case 'BTC':
			return value

		default:
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency,
			}).format(value)
	}
}

export const validateIpAddress = ipAddress => {
	if (
		/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
			ipAddress
		)
	) {
		return true
	}
	return false
}

export const Timezone = [
	{ name: 'Africa/Cairo', hour: -2 },
	{ name: 'Africa/Johannesburg', hour: -2 },
	{ name: 'Africa/Lagos', hour: -1 },
	{ name: 'America/Argentina/Buenos_Aires', hour: 3 },
	{ name: 'America/Bogota', hour: 5 },
	{ name: 'America/Caracas', hour: 4 },
	{ name: 'America/Chicago', hour: 6 },
	{ name: 'America/El_Salvador', hour: 6 },
	{ name: 'America/Juneau', hour: 9 },
	{ name: 'America/Lima', hour: 5 },
	{ name: 'America/Los_Angeles', hour: 8 },
	{ name: 'America/Mexico_City', hour: 6 },
	{ name: 'America/New_York', hour: 5 },
	{ name: 'America/Phoenix', hour: 7 },
	{ name: 'America/Santiago', hour: 3 },
	{ name: 'America/Sao_Paulo', hour: 3 },
	{ name: 'America/Toronto', hour: 5 },
	{ name: 'America/Vancouver', hour: 8 },
	{ name: 'Asia/Almaty', hour: -6 },
	{ name: 'Asia/Ashkhabad', hour: -5 },
	{ name: 'Asia/Bahrain', hour: -3 },
	{ name: 'Asia/Bangkok', hour: -7 },
	{ name: 'Asia/Chongqing', hour: -8 },
	{ name: 'Asia/Dubai', hour: -4 },
	{ name: 'Asia/Ho_Chi_Minh', hour: -7 },
	{ name: 'Asia/Hong_Kong', hour: -8 },
	{ name: 'Asia/Jakarta', hour: -7 },
	{ name: 'Asia/Jerusalem', hour: -2 },
	{ name: 'Asia/Kathmandu', hour: -5.75 },
	{ name: 'Asia/Kolkata', hour: -5.5 },
	{ name: 'Asia/Kuwait', hour: -3 },
	{ name: 'Asia/Muscat', hour: -4 },
	{ name: 'Asia/Qatar', hour: -3 },
	{ name: 'Asia/Riyadh', hour: -3 },
	{ name: 'Asia/Seoul', hour: -9 },
	{ name: 'Asia/Shanghai', hour: -8 },
	{ name: 'Asia/Singapore', hour: -8 },
	{ name: 'Asia/Taipei', hour: -8 },
	{ name: 'Asia/Tehran', hour: -3.5 },
	{ name: 'Asia/Tokyo', hour: -9 },
	{ name: 'Atlantic/Reykjavik', hour: 0 },
	{ name: 'Australia/ACT', hour: -9.5 },
	{ name: 'Australia/Adelaide', hour: -10.5 },
	{ name: 'Australia/Brisbane', hour: -10 },
	{ name: 'Australia/Perth', hour: -8 },
	{ name: 'Australia/Sydney', hour: -11 },
	{ name: 'Europe/Amsterdam', hour: -1 },
	{ name: 'Europe/Athens', hour: -2 },
	{ name: 'Europe/Belgrade', hour: -1 },
	{ name: 'Europe/Berlin', hour: -1 },
	{ name: 'Europe/Brussels', hour: -1 },
	{ name: 'Europe/Copenhagen', hour: -1 },
	{ name: 'Europe/Dublin', hour: 0 },
	{ name: 'Europe/Helsinki', hour: -2 },
	{ name: 'Europe/Istanbul', hour: -3 },
	{ name: 'Europe/Lisbon', hour: 0 },
	{ name: 'Europe/London', hour: 0 },
	{ name: 'Europe/Luxembourg', hour: -1 },
	{ name: 'Europe/Madrid', hour: -1 },
	{ name: 'Europe/Malta', hour: -1 },
	{ name: 'Europe/Moscow', hour: -3 },
	{ name: 'Europe/Oslo', hour: -1 },
	{ name: 'Europe/Paris', hour: -1 },
	{ name: 'Europe/Riga', hour: -2 },
	{ name: 'Europe/Rome', hour: -1 },
	{ name: 'Europe/Stockholm', hour: -1 },
	{ name: 'Europe/Tallinn', hour: -2 },
	{ name: 'Europe/Vilnius', hour: -2 },
	{ name: 'Europe/Warsaw', hour: -1 },
	{ name: 'Europe/Zurich', hour: -1 },
	{ name: 'Pacific/Auckland', hour: -13 },
	{ name: 'Pacific/Chatham', hour: -13.75 },
	{ name: 'Pacific/Fakaofo', hour: -13 },
	{ name: 'Pacific/Honolulu', hour: 10 },
	{ name: 'Pacific/Norfolk', hour: -12 },
	{ name: 'US/Mountain', hour: 7 },
]

export const promiseTimeout = function (ms, promise) {
	const timeout = new Promise(resolve => {
		const id = setTimeout(() => {
			clearTimeout(id)
			resolve('rejected')
		}, ms)
	})

	return Promise.race([promise, timeout])
}

export const buildParams = params => {
	const builderParams = []

	Object.values(params).forEach(({ type, value }) => {
		builderParams.push({
			type,
			value,
		})
	})

	return builderParams
}

const hexToAscii = str1 => {
	const hex = str1.toString()
	let str = ''
	for (let n = 0; n < hex?.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16))
	}
	return str.split('\x00').filter(Boolean)[1]?.slice(1)
}

export const parseTronResponse = ({ method, transaction }) => {
	switch (method) {
		case 'balanceOf':
		case 'tokenOfOwnerByIndex':
		case 'allowance':
			if (transaction?.constant_result) {
				const { constant_result } = transaction
				if (constant_result[0]) {
					return parseInt(constant_result[0], 16) / 10 ** defaultDecimals
				}
			}
			return
		case 'transfer':
			return transaction
		case 'increaseAllowance':
			return transaction?.transaction
		case 'tokenURI':
			const data = transaction?.constant_result[0]
			const tokenUrl = hexToAscii(data)
			return tokenUrl
		case 'approve':
		case 'safeTransferFrom':
			return transaction
		case 'ownerOf':
			const fixedHex = 41 + transaction?.constant_result[0]?.substr(24)
			return fixedHex
		case 'transferFrom':
			return transaction
		default:
			return
	}
}

export const setTokenLocalStorage = items => localStorage.setItem(TOKEN, items)
export const setNftsSoftLocalStorage = items => localStorage.setItem(NFTS_SOFT, items)

export const getTokenLocalStorage = () => localStorage.getItem(TOKEN)
export const getNftsSoftLocalStorage = () => localStorage.getItem(NFTS_SOFT)

export const triggerContract = async ({ contractAddress, triggerMethod, params, address, amount }) => {
	const defaultFeeLimit = { feeLimit: 1000000000 }
	if (!window?.tronWeb) {
		return
	}

	const contract = await window?.tronWeb?.contract()?.at(contractAddress)
	// Methods that need shouldPollResponse
	switch (triggerMethod) {
		case 'increaseAllowance':
			return await contract.increaseAllowance(address, amount).send({
				feeLimit: defaultFeeLimit.feeLimit,
				shouldPollResponse: false,
			})
		case 'marketBuy':
			return await contract.marketBuy(amount).send({
				feeLimit: defaultFeeLimit.feeLimit,
				shouldPollResponse: false,
			})
		default:
			break
	}

	const method = contracts[triggerMethod]

	if (!params) {
		if (triggerMethod === 'paused') {
			const { constant_result } = await window?.tronWeb?.transactionBuilder?.triggerSmartContract(
				contractAddress,
				method,
				{ ...defaultFeeLimit }
			)
			return parseInt(constant_result[0], 16) === 1
		}
		return
	}
	const contractParameters = buildParams(params)
	const transaction = await window?.tronWeb?.transactionBuilder?.triggerSmartContract(
		contractAddress,
		method,
		{ ...defaultFeeLimit },
		contractParameters
	)

	return parseTronResponse({ method: triggerMethod, transaction })
}

export const isBeta = () => {
	return TEST ? false : window.location.hostname === 'beta.bitcoin.me'
}

export const isProd = () => {
	return TEST ? false : window.location.hostname === 'bitcoin.me'
}

export function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms))
}

export const getFirstName = name => {
	if (name) {
		const index = name?.indexOf(' ')
		return name?.slice(0, index)
	}
	return ''
}

export const componentLoader = (lazyComponent, attemptsLeft = 3) => {
	return new Promise((resolve, reject) => {
		lazyComponent()
			.then(resolve)
			.catch(error => {
				setTimeout(() => {
					if (attemptsLeft === 1) {
						reject(error)
						return
					}
					componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject)
				}, 1000)
			})
	})
}

export const checkDecimals = decimals => (decimals > 8 ? 8 : decimals)

export const getTokenPrice = record => new BigNumber(record?.Stats?.Close).multipliedBy(record?.Quote?.Price).toNumber()

export const defineDecimals = price => {
	if (price) {
		if (price >= 10000) {
			return 2
		}
		if (price >= 1000) {
			return 3
		}
		if (price >= 100) {
			return 4
		}
		if (price >= 10) {
			return 5
		}
		if (price > 0) {
			return 6
		}
		return 6
	}
}

export const numberWithoutDots = value => replace(value, /[^0-9.]/g, '')

export const removeCharacters = value => replace(value, /[`"'@<>/|.,`]/gi, '')

export const twoDots = () => /(\..*){2,}/

export const validNumberToInput = (value, e, decimals) => {
	let newValue = value
	if (!value) return
	if (value < 0 || excludeInputChars.includes(e?.nativeEvent?.data)) return
	if (value?.toString()?.includes('E') || value?.toString()?.includes('e')) {
		newValue = value?.toString()?.replace(/[eE]/gi, '')
	}
	if (newValue?.toString()?.length > 13) return
	if (twoDots().test(newValue?.toString())) {
		return
	}

	if (decimalsBiggerThanPrecision(newValue, decimals)) {
		return rawPriceFormat(newValue, decimals)
	}

	return newValue
}

export const sortNetworksWithACoinOnTop = networks => {
	const FIXED_VALUE = process.env.REACT_APP_NETWORKS_TOP_COIN_LIST || 'KLV'

	const compareSort = (a, b) => {
		if (a?.Name === FIXED_VALUE) {
			return -1
		}
		if (b?.Name === FIXED_VALUE) {
			return 1
		}
		return b?.Name?.localeCompare(a?.Name)
	}

	return sortBy(networks, compareSort)
}

export const secondsToMonths = seconds => {
	const months = seconds / 2629008
	return months.toFixed(0)
}

export const normalAndBonusAndHolderAllowanceCalculate = (tokensList, tokenInfo, liquidity) => {
	const [normalRewardsInCurrency, bonusRewardsInCurrency, holderRewardsInCurrency] = liquidity?.allowances?.reduce(
		(acc, allowance) => {
			const price = tokenInfo(tokensList, allowance?.tokenID)?.Price

			const amount = BigNumber(allowance?.allowance || 0)
				.multipliedBy(price || 0)
				.toNumber()

			if (!allowance?.isBonus && !allowance?.isHolder) {
				acc[0].push(amount)
			}

			if (allowance?.isBonus) {
				acc[1].push(amount)
			}

			if (allowance?.isHolder) {
				acc[2].push(amount)
			}

			return acc
		},
		[[], [], []]
	) ?? [[], [], []]

	return {
		normalRewardsInCurrency: normalRewardsInCurrency?.reduce((acc, cur) => acc + cur, 0),
		bonusRewardsInCurrency: bonusRewardsInCurrency?.reduce((acc, cur) => acc + cur, 0),
		holderRewardsInCurrency: holderRewardsInCurrency?.reduce((acc, cur) => acc + cur, 0),
	}
}

const retryPromise = async (promise, maxRetry = 5, interval = 1000) => {
	let retryCount = 0

	while (retryCount <= maxRetry) {
		try {
			return await promise
		} catch (e) {
			if (retryCount > maxRetry) throw e
			await sleep(interval)
			retryCount++
		}
	}
}

export const saveTokens = ({ token, refreshToken, expToken, expRefresh, logged, name, email, iat, userId }) => {
	const tokensStorage = JSON.parse(localStorage.getItem(STORAGE_TOKENS))

	const newTokens = {
		token: token ?? tokensStorage?.token,
		refreshToken: refreshToken ?? tokensStorage?.refreshToken,
		expToken: expToken ?? tokensStorage?.expToken,
		expRefresh: expRefresh ?? tokensStorage?.expRefresh,
		logged: logged ?? tokensStorage?.logged,
		name: name ?? tokensStorage?.name,
		email: email ?? tokensStorage?.email,
		iat: iat ?? tokensStorage?.iat,
		userId: userId ?? tokensStorage?.userId,
	}

	for (const key in newTokens) {
		if (!newTokens[key]) {
			delete newTokens[key]
		}
	}

	localStorage.setItem(STORAGE_TOKENS, JSON.stringify(newTokens))
}

export const isAuthenticatedUser = () => {
	const tokens = JSON.parse(localStorage.getItem(STORAGE_TOKENS))

	return tokens?.logged
}

export const getAccessToken = async () => {
	const tokens = JSON.parse(localStorage.getItem(STORAGE_TOKENS))
	const isTokenExpired = (tokens?.expToken || 0) < Date.now()
	const isRefreshTokenExpired = (tokens?.expRefresh || 0) < Date.now()

	if (isTokenExpired && !isRefreshTokenExpired && tokens?.logged) {
		try {
			const { data } = await retryPromise(
				axios.post(
					`${process.env.REACT_APP_KEYCLOAK_URL}realms/klever-id/protocol/openid-connect/token`,
					qs.stringify({
						grant_type: 'refresh_token',
						refresh_token: tokens?.refreshToken,
						client_id: process.env.REACT_APP_KEYCLOAK_CLIENTID,
					}),
					{
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
						},
					}
				)
			)

			saveTokens({
				token: data?.access_token,
				refreshToken: data?.refresh_token,
				expToken: Date.now() + data?.expires_in * 1000,
				expRefresh: Date.now() + data?.refresh_expires_in * 1000,
				logged: true,
			})

			return data?.access_token
		} catch (error) {
			return null
		}
	}

	if (isTokenExpired && isRefreshTokenExpired && tokens?.logged) {
		localStorage.removeItem(STORAGE_TOKENS)
		return
	}

	if (!isTokenExpired && isRefreshTokenExpired && tokens?.logged) {
		localStorage.removeItem(STORAGE_TOKENS)
		return
	}

	if (!isTokenExpired && tokens?.logged) {
		return tokens?.token
	}

	return null
}

export const mountTickers = (keypair, setMethod, hasMethod = true) => {
	const Tickers = keypair?.Tickers || []
	const emptyArray = []
	if (Tickers?.length === 0) {
		for (let index = 0; index < 20; index++) {
			emptyArray.push({ x: index, y: 0 })
		}
		if (hasMethod) {
			setMethod(emptyArray)
		}
		return emptyArray
	} else {
		const array = Tickers.map((ticker, index) => {
			return { x: index || 0, y: ticker?.Close || 0 }
		})
		if (hasMethod) {
			setMethod(array || [])
		}
		return array
	}
}

export const fixedDecimalsAndLeadingZeros = (value, fixedDecimals = 6) => {
	let result = BigNumber(value).toFixed(fixedDecimals, 1)

	if (parseFloat(result.split('.')[1]) === 0) {
		result = `${result.split('.')[0]}.00`
	}

	return result
}

export const labelAndCurrency = (label, currency) => `${label?.toUpperCase()} | ${currency?.symbol} `

export const renderBadgeStatus = status => {
	const results = {
		DONE: {
			background: '#37dd72',
			text: 'status.done',
		},
		PENDING: {
			background: '#ffc542',
			text: 'status.pending',
		},
		PROGRESS: {
			background: '#ffc542',
			text: 'status.progress',
		},
		ERROR: {
			background: '#ff4d4f',
			text: 'status.error',
		},
		BLOCKED: {
			background: '#ff4d4f',
			text: 'status.blocked',
		},
		UNVERIFIED: {
			background: '#3498db',
			text: 'status.unverified',
		},
	}
	return results[status]
}

export const tokenInfoUtils = (tokensList, tokenId) => {
	if (isEmpty(tokensList) || !tokenId) return
	return tokensList?.find(token => token?.ID === tokenId)
}

export const tokenInfoUtilsByAbbr = (tokensList, tokenAbbr) => {
	if (isEmpty(tokensList) || !tokenAbbr) return
	return tokensList?.find(token => token?.Abbr === tokenAbbr)
}

export const addMetadataToNfts = async nfts => {
	const response = Promise?.allSettled(
		nfts?.map(async nft => {
			const metadata = await fetchNFTMetadata(nft?.id)
			return { ...nft, metadata }
		})
	)
	return response
}

export const scrollToChildUtils = (parentRef, childRef) => {
	const parentElement = parentRef.current
	const childElement = childRef.current

	if (parentElement instanceof HTMLElement && childElement instanceof HTMLElement) {
		const parentRect = parentElement.getBoundingClientRect()
		const childRect = childElement.getBoundingClientRect()

		const scrollTop = childRect.top - parentRect.top + parentElement.scrollTop

		if (typeof parentElement.scrollTo === 'function') {
			parentElement?.scrollTo({
				top: scrollTop,
			})
		}
	}
}

export const totalBalance = (balances, tokensList) => {
	let amount = 0
	balances?.forEach(element => {
		const price = tokenInfoUtils(tokensList, element?.TokenID)?.Price
		const elementAmount = parseFloat(
			BigNumber(element?.Balance || 0).multipliedBy(price || 0) || 0,
			element?.Precision
		)
		amount += elementAmount
	})
	return amount
}

export const poolIsActive = (startTime, endTime) => {
	const today = moment()?.unix()
	const start = moment(startTime)?.unix()
	const end = moment(endTime)?.unix()
	return today >= start && today <= end
}

export const poolIsOpen = startTime => {
	const today = moment()?.unix()
	const start = moment(startTime)?.unix()
	return today < start
}

export const poolIsFinished = endTime => {
	const today = moment()?.unix()
	const end = moment(endTime)?.unix()
	return today > end
}

export const exceedsSpread = (side, price, currentPrice) => {
	const averagePercent = BigNumber(price).multipliedBy(100).dividedBy(currentPrice).minus(100).toNumber()

	if (side === 'BUY') {
		return averagePercent > 5
	} else {
		return averagePercent < -5
	}
}

export const calcMarketErrorMessages = (toast, error, t) => {
	const message = error?.split('orderbook:')[1]

	if (message === ' insufficient quantity to calculate price' || message === ' active orders not found') {
		toast.error(t(`messages.order_has_larger_amount`))
	} else {
		toast.error(t(`messages.failed_to_calculate_market_order`))
	}

	return false
}
